<template>
    <noscript
        ><div><img v-if="id" :src="link" style="position: absolute; left: -9999px" alt="" /></div
    ></noscript>
</template>
<script>
export default {
    name: 'YandexCounter',
    props: {
        id: String,
    },
    data: () => ({}),
    created() {
        let id = this.id;

        (function (d, w, c) {
            (w[c] = w[c] || []).push(function () {
                try {
                    // eslint-disable-next-line no-undef
                    w['yaCounter' + id] = new Ya.Metrika({
                        id: id,
                        accurateTrackBounce: true,
                        webvisor: true,
                        triggerEvent: true,
                        clickmap: true,
                        trackLinks: true,
                    });
                } catch (e) {
                    console.log(e);
                }
            });

            var n = d.getElementsByTagName('script')[0],
                s = d.createElement('script'),
                f = function () {
                    n.parentNode.insertBefore(s, n);
                };
            s.type = 'text/javascript';
            s.async = true;
            s.src = 'https://mc.yandex.ru/metrika/watch.js';

            if (w.opera == '[object Opera]') {
                d.addEventListener('DOMContentLoaded', f, false);
            } else {
                f();
            }
        })(document, window, 'yandex_metrika_callbacks');
    },
    computed: {
        link() {
            return 'https://mc.yandex.ru/watch/' + this.id;
        },
    },
};
</script>
