<template>
    <div>
        <noscript>
            <div style="display: inline">
                <img height="1" width="1" style="border-style: none" alt="" :src="link" />
            </div>
        </noscript>
        <div id="gaw"></div>
    </div>
</template>
<script>
import { STORAGE } from '@/api/storage';
export default {
    name: 'GoogleAdWords',
    props: {
        id: String,
    },
    data: () => ({
        payment: STORAGE.getPayment(),
    }),
    created() {
        setTimeout(() => {
            var ta = document.createElement('script');
            ta.type = 'text/javascript';
            ta.async = true;
            ta.src = '//www.googleadservices.com/pagead/conversion.js';
            var s = document.getElementById('gaw');
            s.parentNode.insertBefore(ta, s);
        }, 100);
    },
    computed: {
        link() {
            return (
                '//www.googleadservices.com/pagead/conversion/' +
                this.id +
                '/?value=' +
                this.payment.orderSum +
                '&currency_code=' +
                this.payment.orderCurrency +
                '&label=' +
                this.payment.id +
                '&guid=ON&script=0'
            );
        },
    },
};
</script>
